import * as React from 'react';
import Title from "../Common/Title";
import {StaticImage} from "gatsby-plugin-image";
import {getImageAltText} from "../../utils/common";

const About = () => (
  <div className="panel mb-5 about">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-lg-6 col-md-6 order-2 order-md-1 mt-4 pt-2 mt-sm-0 opt-sm-0">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6 col-6">
              <div className="row">
                <div className="col-lg-12 col-md-12 mt-4 pt-2">
                  <StaticImage
                      src="../../assets/images/about/block-left.jpg"
                      className="img-fluid"
                      placeholder="blurred"
                      alt={getImageAltText()}
                      imgStyle={{
                        borderRadius: '12px',
                        border: '1px solid white',
                      }}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-6">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <StaticImage
                      src="../../assets/images/about/block-right1.jpg"
                      className="img-fluid"
                      alt={getImageAltText()}
                      placeholder="blurred"
                      imgStyle={{
                        borderRadius: '12px',
                        border: '1px solid white',
                      }}
                  />
                </div>

                <div className="col-lg-12 col-md-12 mt-4 pt-2">
                  <StaticImage
                      src="../../assets/images/about/block-right2.jpg"
                      className="img-fluid" alt={getImageAltText()}
                      placeholder="blurred"
                      imgStyle={{
                        borderRadius: '12px',
                        border: '1px solid white',
                      }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-6 col-12 order-1 order-md-2">
          <div className="section-title ml-lg-5">
            <div className="mb-3">
              <Title position="left">Про нас</Title>
            </div>
            <h4 className="title mb-4">
              «Академія лазерної медицини»
            </h4>
            <p className="text-muted mb-0">
              заклад системи охорони здоров’я, в якому надається <span>широкий спектр медичних послуг</span>
              для всіх вікових категорій населення, а також проводить навчання та підвищення
              кваліфікації лікарів інших медичних закладів.</p>

          </div>
        </div>
      </div>
    </div>
  </div>
);

export default About;