import * as React from 'react';
import Block1 from '/src/assets/images/svg_icons/001-body care.svg'
import Block2 from '/src/assets/images/svg_icons/031-flowers.svg'
import Block3 from '/src/assets/images/svg_icons/070-bondage.svg'
import Block4 from '/src/assets/images/svg_icons/021-face.svg'
import {Link} from "gatsby";
import {getImageAltText} from "../../utils/common";

const FourInfoBlocks = () => (
  <div className="container">
    {/*<div className="Row text-center">*/}
    {/*  <Title>Наші основні напрямки</Title>*/}
    {/*</div>*/}
    <div className="row fourInfoBlocks">
      <div className="col-lg-3 col-sm-6 col-12 mb-5">
        <Link to="/services">
        <div className="panel">
          <div className="panel-body text-center">
            {/*<p className="text-uppercase mar-btm text-sm danger">Дермато-хірургія</p>*/}
            <img src={Block1} className="img-fluid" alt={getImageAltText()}/>
            <hr/>
            <h4 className="title">Дермато-хірургія</h4>
          </div>
        </div>
        </Link>
      </div>
      <div className="col-lg-3 col-sm-6 col-12 mb-5">
        <Link to="/services">
        <div className="panel">
          <div className="panel-body text-center">
            {/*<p className="text-uppercase mar-btm text-sm">Гінекологія</p>*/}
            <img src={Block2} className="img-fluid" alt={getImageAltText()}/>
            <hr/>
            <h4 className="title">Гінекологія</h4>
          </div>
        </div>
        </Link>
      </div>
      <div className="col-lg-3 col-sm-6 col-12 mb-5">
        <Link to="/services">
        <div className="panel">
          <div className="panel-body text-center">
            {/*<p className="text-uppercase mar-btm text-sm">Хірургія</p>*/}
            <img src={Block3} className="img-fluid" alt={getImageAltText()}/>
            <hr/>
            <h4 className="title">Хірургія</h4>
          </div>
        </div>
        </Link>
      </div>
      <div className="col-lg-3 col-sm-6 col-12 mb-5">
        <Link to="/services">
        <div className="panel">
          <div className="panel-body text-center">
            {/*<p className="text-uppercase mar-btm text-sm">Пластична хірургія</p>*/}
            <img src={Block4} className="img-fluid" alt={getImageAltText()}/>
            <hr/>
            <h4 className="title">Пластична хірургія</h4>
          </div>
        </div>
        </Link>
      </div>
    </div>
  </div>
);

export default FourInfoBlocks;