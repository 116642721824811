import React from "react";

const Title = ({children, position}) => (
  <div className={position === "left" ? "h3-wrapper-left" : "h3-wrapper-center"}>
    <h3>
      {children}
    </h3>
  </div>
);

export default Title;
